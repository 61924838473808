import React from 'react'
import Logo from "./logo"

// import ScrollTop from "./scrolltop";

const App = () => {
  return (
    <div>
      <Logo/>
      {/* <ScrollTop/> */}
    </div>
  )
}

export default App